<template>
  <div
    ref="top"
    class="landing-page"
  >
    <div class="content">
      <nuxt-page />
    </div>
    <app-lp-footer />
  </div>
</template>

<style lang="scss">
.landing-page {
  overflow: hidden;
  color: $lp-color-17;
  background-color: $white-color;

  ul,
  ol,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  strong {
    font-weight: $font-weight-bold;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .link {
    &:hover {
      opacity: 0.8;
    }
  }

  .link-arrow {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    font-weight: $font-weight-bold;
    color: $primary-color;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 8px;
      height: 8px;
      margin-top: -3px;
      content: '';
      border-top: solid 2px $primary-color;
      border-right: solid 2px $primary-color;
      transform: rotate(45deg);
    }

    &:hover {
      opacity: 0.8;
    }

    &.-white {
      color: $white-color;

      &::after {
        border-top-color: $white-color;
        border-right-color: $white-color;
      }
    }

    &.-black {
      color: $lp-color-16;

      &::after {
        border-top-color: $lp-color-16;
        border-right-color: $lp-color-16;
      }
    }

    &.-left {
      padding-right: 0;
      padding-left: 10px;

      &::after {
        position: absolute;
        top: 50%;
        right: initial;
        left: 0;
        width: 6px;
        height: 6px;
        margin-top: -3px;
        content: '';
        border-top: solid 2px $primary-color;
        border-right: solid 2px $primary-color;
        transform: rotate(225deg);
      }
    }
  }
}
</style>
